import { Table, Button, Card, Form, message } from "antd";
import FormMulti from "Components/form/FormMulti";
import { SYMPTOMS } from "Constants";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";

import {
  createSymptoms,
  getSymptoms,
  updateSymptoms,
  deleteSymptoms,
} from "Api";

const Symptoms = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [context, setContext] = useState(1);
  const [symptom, setSymptom] = useState([]);

  const fetchSymptoms = () => {
    if (selected) {
      getSymptoms({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data;
        if (data) {
          setSymptom(data);
        }
      });
    }
  };

  const onFinish = (values) => {
    if (selected && values) {
      let symptomsArray = values.symptom;
  
      if (!symptomsArray || symptomsArray.length === 0) {
        message.error("Please select at least one symptom before saving.");
        return;
      }
  
      if (typeof symptomsArray === "string") {
        symptomsArray = symptomsArray.split(",").map((item) => item.trim());
      }
  
      symptomsArray = Array.isArray(symptomsArray) ? symptomsArray : [symptomsArray]; // Ensure it's an array
  
      const body = {
        ltbi_no: selected.ltbi_no,
      };
  
      if (context === 1) {
        // Create a new symptom for each selected item in 'symptom'
        Promise.all(
          symptomsArray.map((symptomValue) => {
            const newBody = { ...body, symptom: symptomValue };
            return createSymptoms(newBody).then((data) => {
              return { ...newBody, created_at: new Date().toISOString() };
            });
          })
        ).then((newSymptoms) => {
          setSymptom((prevSymptoms) => [...prevSymptoms, ...newSymptoms]);
          form.resetFields();
          message.success('Symptoms saved successfully!');  
        }).catch((error) => {
          message.error('Failed to save symptoms.'); 
        });
        
      } else if (context === 2) {
        body.id = form.getFieldValue("id");
  
        updateSymptoms(body).then((data) => {
          setSymptom((prevSymptoms) =>
            prevSymptoms.map((symp) => (symp.id === body.id ? { ...symp, ...body } : symp))
          );
          setContext(1);  
          message.success('Symptoms updated successfully!');  
        }).catch((error) => {
          message.error('Failed to update symptoms.');  
        });
      }
    }
  };
  
  useEffect(() => {
    if (selected) {
      fetchSymptoms();
    } else {
      form.resetFields();
    }
  }, [selected]);

  const columns = [
    {
      title: "Symptom",
      width: 100,
      dataIndex: "symptom",
      key: "symptom",
    },
    {
      title: "Followup date",
      width: 100,
      dataIndex: "created_at",
      key: "created_at",
      render: (created) => {
        return new Date(created).toLocaleDateString();
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, record) => (
        !selected?.finished  &&
        <div className="row">
          <div className="col-md-4">
            <Button
              style={{ color: "red", marginLeft: "10px" }}
              onClick={() => {
                deleteSymptoms({ id: record.id }).then(() => {
                  // Remove the deleted symptom from the table
                  setSymptom((prevSymptoms) =>
                    prevSymptoms.filter((symp) => symp.id !== record.id)
                  );
                  message.success('Symptom deleted successfully!');
                });
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-4">
            <div className="col-md-12">
              <FormMulti
                label="Symptoms"
                name="symptom"
                options={SYMPTOMS}
                setVal={(key, val) => {
                  form.setFieldValue(key, val);
                }}
                defaults={[]}
              />
            </div>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={selected?.finished }>
                Add Symptom
              </Button>
            </Form.Item>
            {!!selected?.finished  && (
              <p style={{ color: "red", fontWeight: "bold", textAlign: "left" }}>
                Treatment already finished
              </p>
            )}
          </div>
          <div className="col-md-8">
            <Table
              columns={columns}
              dataSource={symptom}
              rowKey="id"  // Add a unique identifier for the rows
              scroll={{ x: 400 }}
              sticky
              className="mt-3"
            />
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default Symptoms;
