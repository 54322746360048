import { Table, Button, Card, Form, message } from "antd";
import FormInput from "Components/form/FormInput";
import FormRadioGroup from "Components/form/FormRadioGroup";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { YESNO } from "Constants";

import { createDiagnosis, getDiagnosis } from "Api";

const Examinations = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);

  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [examinations, setExaminations] = useState([]); // State for table data
  const [context, setContext] = useState(1); // Context to handle Add or Update

  const calculateBmi = (weight, height) => {
    if (height > 0) {
      const heightInMeters = height / 100;
      return (weight / (heightInMeters * heightInMeters)).toFixed(2);
    }
    return 0;
  };

  const loadExaminations = () => {
    if (selected) {
      getDiagnosis({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data || [];
        setExaminations(data);
      });
    }
  };

  const onFinish = (values) => {
    const body = {
      ltbi_no: selected.ltbi_no,
      hiv: values.hiv,
      ltbi_category: values.ltbi_category,
      weight: values.weight,
      height: values.height,
      general: values.general,
      cardio: values.cardio,
      respiratory: values.respiratory,
      other: values.other,
    };

    if (context === 1) {
      createDiagnosis(body)
        .then(() => {
          form.resetFields();
          message.success("Diagnosis saved successfully!");

          loadExaminations(); // Refresh the table
        })
        .catch(() => {
          message.error("Failed to save diagnosis.");
        });
    } else {
      body.id = form.getFieldValue("id");
      createDiagnosis(body)
        .then(() => {
          setContext(1);
          form.resetFields();
          loadExaminations();
          message.success("Diagnosis updated successfully!");
        })
        .catch(() => {
          message.error("Failed to update diagnosis.");
        });
    }
  };

  // Delete examination record
  // const handleDelete = (record) => {
  //   deleteDiagnosis({ id: record.id }).then(() => {
  //     setExaminations((prev) => prev.filter((item) => item.id !== record.id));
  //   });
  // };

  // Populate form when editing a record
  const handleEdit = (record) => {
    setContext(2); // Set context to update
    Object.entries(record).forEach(([key, value]) => {
      form.setFieldValue(key, value);
      if (key === "weight") setWeight(value);
      if (key === "height") setHeight(value);
    });
  };

  useEffect(() => {
    if (selected) {
      form.resetFields();
      loadExaminations();
    }
  }, [selected]);

  useEffect(() => {
    form.setFieldsValue({
      bmi: calculateBmi(weight, height),
    });
  }, [weight, height, form]);

  const columns = [
    {
      title: "HIV Positive",
      dataIndex: "hiv",
      key: "hiv",
      render: (hiv) => (hiv ? "Yes" : "No"),
    },
    {
      title: "BMI",
      dataIndex: "bmi",
      key: "bmi",
      render: (_, record) => calculateBmi(record.weight, record.height),
    },
    {
      title: "Weight (kg)",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Height (cm)",
      dataIndex: "height",
      key: "height",
    },
    {
      title: "General Examination",
      dataIndex: "general",
      key: "general",
    },
    {
      title: "Cardiovascular",
      dataIndex: "cardio",
      key: "cardio",
    },
    {
      title: "Respiratory",
      dataIndex: "respiratory",
      key: "respiratory",
    },
    {
      title: "Other",
      dataIndex: "other",
      key: "other",
    },
    {
      title: "Action",
      key: "operation",
      render: (_, record) => (
        !selected?.finished  &&
        <div className="row">
          <div className="col-md-4">
            <Button
              style={{ color: "green" }}
              onClick={() => handleEdit(record)}
            >
              Edit
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormRadioGroup label="HIV Positive" name="hiv" options={YESNO} disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="BMI (kg/m²)" name="bmi" type="text" readOnly disabled={selected?.finished }/>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-3"></div>
          <div className="col-md-3">
            <FormRadioGroup
              label="LTBI Category"
              name="ltbi_category"
              options={YESNO}
            disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput
              label="Weight (kg)"
              name="weight"
              type="number"
              onChange={(e) => setWeight(parseFloat(e.target.value))}
            disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput
              label="Height (cm)"
              name="height"
              type="number"
              onChange={(e) => setHeight(parseFloat(e.target.value))}
            disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="General Examinations" name="general" disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="Cardiovascular system" name="cardio" disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="Respiratory system" name="respiratory"disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="Other findings" name="other" disabled={selected?.finished }/>
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" disabled={selected?.finished }>
              {context === 1 ? "Add" : "Update"}
            </Button>
          </Form.Item>
          {!!selected?.finished  && (
              <p style={{ color: "red", fontWeight: "bold", textAlign: "left" }}>
                Treatment already finished
              </p>
            )}
        </div>
      </Card>

      <Table
        columns={columns}
        dataSource={examinations}
        rowKey="id"
        className="mt-3"
      />
    </Form>
  );
};

export default Examinations;
