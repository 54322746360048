import { Button, Card, Form, Table } from "antd";
import FormInput from "Components/form/FormInput";
import FormRadioGroup from "Components/form/FormRadioGroup";
import { GENDER, DISTRICTS, INVESTIGATIONS, CXR, REGIME,OUTCOME } from "Constants";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import { getPatientInvestigations, getPatientOutcome, getDrug } from "Api";
import FormSelect from "Components/form/FormSelect";

const TreatmentCard = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [investigations, setInvestigations] = useState([]);
  const [outcome, setOutcome] = useState({"date_treatment_completed":"","treatment_outcome":""});
  const [regime, setRegime] = useState("");
  const [age, setAge] = useState("");
  const [editable, setEditable] = useState(false);
  const [editableData, setEditableData] = useState({});

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  

  const calcAge = (dob) => {
    if (!dob) return;
    const birthDate = new Date(dob);
    const currentDate = new Date();
    const yearsDiff = currentDate.getFullYear() - birthDate.getFullYear();
    const monthsDiff = currentDate.getMonth() - birthDate.getMonth();
    const adjustedYears = monthsDiff < 0 ? yearsDiff - 1 : yearsDiff;
    const adjustedMonths = (monthsDiff + 12) % 12;
    setAge(`${adjustedYears} years, ${adjustedMonths} months`);
  };

  useEffect(() => {
    if (selected) {
      setEditableData({
        "district" : selected?.district,
        "chest_clinic_contact_no" : selected?.chest_clinic_contact_no,
        "name" : selected?.name,
        "ltbi_patient_no" : selected?.ltbi_patient_no,
        "permanent_address" : selected?.permanent_address,
        "mobile_phone" : selected?.mobile_phone,
      });
      calcAge(selected.dob);
      loadInvestigations(selected.ltbi_no);
      loadOutcome(selected.ltbi_no);
      loadDrug(selected.ltbi_no);
    } else {
      form.resetFields();
    }
  }, [selected]);

  const handleEdit = () => {
    setEditable(true);
    form.setFieldsValue({
      "district" : editableData?.district,
      "chest_clinic_contact_no" : editableData?.chest_clinic_contact_no,
      "name" : editableData?.name,
      "ltbi_patient_no" : editableData?.ltbi_patient_no,
      "permanent_address" : editableData?.permanent_address,
      "mobile_phone" : editableData?.mobile_phone,
    });
  }

  const handleSave = () => {
    const updatedData = form.getFieldsValue();
    setEditableData(updatedData);
    setEditable(false);
  };

  const loadInvestigations = (ltbi_no) => {
    if (ltbi_no) {
      getPatientInvestigations({ id: ltbi_no }).then((res) => {
        setInvestigations(res?.data || []);
      });
    }
  };

  const loadOutcome = (ltbi_no) => {
    if (ltbi_no) {
      getPatientOutcome({ id: ltbi_no }).then((res) => {
        const data = res?.data?.at(0);
        if(data){
          const foundObject = OUTCOME.find(item => item.value == data?.outcome);
          setOutcome({
            "date_treatment_completed": foundObject? foundObject.text : 'N/A',
            "treatment_outcome":data?.date_out ? dayjs(data?.date_out).format('YYYY-MM-DD') : 'N/A'
          });
        }
        
      });
    }
  };

  const loadDrug = (ltbi_no) => {
    if (ltbi_no) {
      getDrug({ id: ltbi_no }).then((res) => {
        // find max id then select data object
        // const data = res?.data.reduce((latest, current) => {
        //   return current.id > latest.id ? current : latest;
        // }, res?.data[0]);
        const data = res?.data?.at(-1);
        if(data){
          const foundObject = REGIME.find(item => item.value == data?.regime);
          setRegime(foundObject? foundObject.text : 'N/A');
        }
      });
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "test_date",
      key: "test_date",
      render: (text) => dayjs(text).format("YYYY-MM-DD"),
    },
    {
      title: "Investigation",
      dataIndex: "type",
      key: "type",
      render: (text) => {
        const foundObject = INVESTIGATIONS.find((item) => item.value == text);
        return foundObject ? foundObject.text : text;
      },
    },
    {
      title: "Results",
      dataIndex: "result",
      key: "result",
      render: (text) => {
        const foundObject = CXR.find((item) => item.value == text);
        return foundObject ? foundObject.text : text;
      },
    },
  ];
  return (
    <>
      <div className="d-flex flex-column align-items-center">
        <div style={{ width: "800px" }}>
          <br />
          <div className="d-flex justify-content-end mb-3">
            {!editable  ? <>
              <Button
                style={{ width: "max-content" }}

                onClick={handleEdit}
                type="primary"
              >
                Edit
              </Button>
              <Button
                style={{ width: "max-content", backgroundColor: "#52c41a", borderColor: "#52c41a", marginRight: "0", marginLeft: "16px" }}
                type="success"
                className="fos-btn-primary"
                onClick={handlePrint}
                title="Print certificate"
              >
                Print Treatment Card
              </Button> </>
              :
              <Button
              style={{ width: "max-content" }}
              type="primary"
              onClick={handleSave}
            >
              Save To Card
            </Button>
            }
          </div>

          <div ref={componentRef}>
            <Form form={form} layout="vertical">
              <Card
                headStyle={{ textAlign: "center", fontSize:"20px", fontWeight:"bold" }}
                title="LTBI Treatment Card"
                className="p-4"
                style={{ width: "800px"}}
              >

                <div className="row">
                  { editable ? 
                  <div className="col-md-6">
                    <FormSelect
                      label="District"
                      name="district"
                      options={DISTRICTS}
                      className="col-md-12"
                      value={editableData?.district}
                    />
                  </div>
                  : 
                  <div className="col-md-6 mb-4">
                    <span style={{ fontWeight: "bold" }}>
                    District
                    </span>
                    <div>
                      {DISTRICTS.find(item => item.value == editableData?.district)?.text  || "\u00A0"}
                    </div>
                  </div>
                  }
                  { editable ? 
                  <div className="col-md-6">
                    <FormInput
                      label="Chest Clinic Contact No."
                      name="chest_clinic_contact_no"
                      className="col-md-12"
                      value={editableData?.chest_clinic_contact_no}
                    />
                  </div>
                  : 
                  <div className="col-md-6 mb-4">
                    <span style={{ fontWeight: "bold" }}>
                    Chest Clinic Contact No.
                    </span>
                    <div>
                      {editableData?.chest_clinic_contact_no  || "\u00A0"}
                    </div>
                  </div>
                  }
                  { editable ? 
                  <div className="col-md-6">
                    <FormInput
                      type="text"
                      label="Full Name"
                      name="name"
                      className="col-md-12"
                      value={editableData?.name}

                    />
                  </div>
                  : 
                  <div className="col-md-6 mb-4">
                    <span style={{ fontWeight: "bold" }}>
                    Full Name
                    </span>
                    <div>
                      {editableData?.name  || "\u00A0"}
                    </div>
                  </div>
                  }
                  { editable ? 
                  <div className="col-md-6">
                    <FormInput
                      type="text"
                      label="District LTBI No."
                      name="ltbi_patient_no"
                      className="col-md-12"
                    />
                  </div>
                  : 
                  <div className="col-md-6 mb-4">
                    <span style={{ fontWeight: "bold" }}>
                    District LTBI No.
                    </span>
                    <div>
                      {editableData?.ltbi_patient_no  || "\u00A0"}
                    </div>
                  </div>
                  }
                  <div className="col-md-6 mb-4">
                    <span style={{ fontWeight: "bold" }}>
                    Age (years/months)
                    </span>
                    <div>
                      {age}
                    </div>
                  </div>
                  <div className="col-md-6 mb-4">
                    <span style={{ fontWeight: "bold" }}>
                    Sex
                    </span>
                    <div>
                      {GENDER.find(item => item.value == selected?.sex)?.text || "\u00A0"}
                    </div>
                  </div>
                  { editable ? 
                  <div className="col-md-6">
                    <FormInput
                      type="textarea"
                      rows="3"
                      label="Complete Address"
                      name="permanent_address"
                      className="col-md-12"
                    />
                  </div>
                  : 
                  <div className="col-md-6 mb-4">
                    <span style={{ fontWeight: "bold" }}>
                    Complete Address
                    </span>
                    <div>
                    {editableData?.permanent_address 
                      ? editableData.permanent_address.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))
                      : "\u00A0"}
                    </div>
                  </div>
                  }
                  { editable ? 
                  <div className="col-md-6">
                    <FormInput
                      label="Mobile No."
                      name="mobile_phone"
                      className="col-md-12"
                    />
                  </div>
                  : 
                  <div className="col-md-6 mb-4">
                    <span style={{ fontWeight: "bold" }}>
                    Mobile No.
                    </span>
                    <div>
                      {editableData?.mobile_phone  || "\u00A0"}
                    </div>
                  </div>
                  }
                </div>

                {/* Investigations Table */}
                <Card title="Investigations" className="mb-4">
                  <Table
                    dataSource={investigations}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                  />
                </Card>
                <div className="row mb-3 mb-4">
                  <div className="col-md-6">
                    <span style={{ fontWeight: "bold" }}>
                      Date Treatment Started
                    </span>
                    <div>
                      { selected && selected?.created ? dayjs(selected.created).format('YYYY-MM-DD') : "\u00A0"}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span style={{ fontWeight: "bold" }}>
                      Date Treatment Completed
                    </span>
                    <div>
                    { outcome.date_treatment_completed || "\u00A0"}
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <span style={{ fontWeight: "bold" }}>
                      Treatment Regime
                    </span>
                    <div>
                      {regime || "\u00A0"}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <span style={{ fontWeight: "bold" }}>
                      Treatment Outcome
                    </span>
                    <div>
                      { outcome.treatment_outcome || "\u00A0"}
                    </div>
                  </div>
                </div>
              </Card>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default TreatmentCard;
