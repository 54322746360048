import { Button, Card, Form, message } from "antd";
import FormInput from "Components/form/FormInput";
import FormRadioGroup from "Components/form/FormRadioGroup";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import { PRESENTPAST } from "Constants";
import { createHistory, getHistory } from "Api";

const History = ({ historyData, setHistoryData }) => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);

  const onFinish = (values) => {
    console.log("Success:", values);
    if (selected && values) {
      const body = {
        ltbi_no: selected.ltbi_no,
        tb_history: values.tb_history,
        medical_history: values.medical_history,
        surgical_history: values.surgical_history,
        drug_allergies: values.drug_allergies,
        food_allergies: values.food_allergies,
        smoking: values.smoking,
        alcohol: values.alcohol,
        illicit: values.illicit,
      };

      createHistory(body)
      .then((response) => {
        message.success("History saved successfully!");
      })
      .catch((error) => {
        message.error("Failed to save History.");
      });
  } else {
    message.warning("No History selected or invalid form data.");
  }
  };

  useEffect(() => {
    console.log({ selected });
    if (selected) {
      console.log("SELECTED", selected);
      getHistory({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data?.at(0);
        if (!data) {
          return;
        }
        console.log("HISTORY OUT", data);
        Object.entries(data).forEach(([key, value]) => {
          form.setFieldValue(key, value);
        });
      });
    } else {
      form.resetFields();
    }
  }, [form, selected]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormInput label="Past TB history" name="tb_history" disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="Past medical history" name="medical_history" disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="Past surgical history" name="surgical_history" disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="Drug allergies" name="drug_allergies" disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormInput label="Food allergies" name="food_allergies" disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormRadioGroup
              label="Smoking"
              name="smoking"
              options={PRESENTPAST}
            disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormRadioGroup
              label="Alcohol"
              name="alcohol"
              options={PRESENTPAST}
            disabled={selected?.finished }/>
          </div>
          <div className="col-md-3">
            <FormRadioGroup
              label="Illicit drugs"
              name="illicit"
              options={PRESENTPAST}
            disabled={selected?.finished }/>
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" disabled={selected?.finished }>
              Update
            </Button>
          </Form.Item>
          {!!selected?.finished  && (
              <p style={{ color: "red", fontWeight: "bold", textAlign: "left" }}>
                Treatment already finished
              </p>
            )}
        </div>
      </Card>
    </Form>
  );
};

export default History;
