import axios from "axios";

 const BASE_URL = "http://13.213.206.120:4000/api";
// const BASE_URL = "http://localhost:3023/api";

const GET = async (path, body = {}, auth = true, blob = false) => {
  const TOKEN = await window.localStorage.getItem("token");
  if (auth) {
    body.headers = { Authorization: `Bearer ${TOKEN}` };
  }
  if (blob) {
    body.responseType = "blob";
  }
  let res = null;
  try {
    res = await axios.get(path, body, {
      headers: { "Access-Control-Allow-Origin": "*" },
    });
    return res;
  } catch (e) {
    if (e?.request?.status == 403) {
      alert("Not permitted");
      return {
        status: 403,
        data: [],
      };
    }
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

const POST = async (path, body, auth = true) => {
  const TOKEN = await window.localStorage.getItem("token");
  let res = null;
  try {
    res = await axios.post(
      path,
      body,
      auth
        ? {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              "Access-Control-Allow-Origin": "*",
            },
          }
        : { "Access-Control-Allow-Origin": "*" }
    );
    return {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    console.log(e);
    if (e?.request?.status == 403) {
      alert("Not permitted");
      return {
        status: 403,
        data: [],
      };
    }
    return {
      status: 500,
      data: {
        out: [],
        message: e.message,
      },
    };
  }
};

export const login = async (body) => {
  return await POST(BASE_URL + "/users/login", body, false);
};

export const getScreeningItem = (id) =>
  GET(BASE_URL + `/screening/getScreening/${id}`, {});

export const createScreening = async (body) => {
  return await POST(BASE_URL + "/screening/generalInfo", body);
};

export const updateScreening = async (body) => {
  return await POST(BASE_URL + "/screening/updateGeneralInfo", body);
};

export const getScreenings = async (id) => {
  return await GET(BASE_URL + `/screening/getScreenings`, {});
};

export const addInvestigation = async (body) => {
  return await POST(BASE_URL + "/screening/addInvestigation", body);
};

export const updateInvestigation = async (body) => {
  return await POST(BASE_URL + "/screening/updateInvestigation", body);
};

export const getInvestigations = async (id) => {
  return await GET(BASE_URL + `/screening/getInvestigations/${id}`, {});
};

export const getScreeningReport = async (body) => {
  return await GET(BASE_URL + "/screening/getScreeningReport", body);
};

export const getScreeningAgeReport = async (body) => {
  return await POST(BASE_URL + "/screening/getScreeningAgeReport", body);
};

export const getScreeningAgeReportPositive = async (body) => {
  return await POST(
    BASE_URL + "/screening/getScreeningAgeReportPositive",
    body
  );
};

export const addOutcome = async (body) => {
  return await POST(BASE_URL + "/screening/addOutcome", body);
};

export const createPatient = async (body) => {
  return await POST(BASE_URL + "/ltbi/createPatient", body);
};

export const updatePatient = async (body) => {
  return await POST(BASE_URL + "/ltbi/updatePatient", body);
};

export const getPatients = async (body) => {
  return await GET(BASE_URL + "/ltbi/getAllPatients", body);
};

export const getPatient = async (body) => {
  return await GET(BASE_URL + "/ltbi/getPatient/"+ body.id, { body });
};

export const getPatientReport = async (body) => {
  return await GET(BASE_URL + "/ltbi/getPatientReport", body);
};

export const getPatientAgeReport = async (body) => {
  return await POST(BASE_URL + "/ltbi/getPatientAgeReport", body);
};

export const getPatientAgeReportOutcome = async (body) => {
  return await POST(BASE_URL + "/ltbi/getPatientAgeReportOutcome", body);
};

export const getHistory = async (body) => {
  return await GET(BASE_URL + "/ltbi/getHistory/" + body.id, { body });
};

export const createHistory = async (body) => {
  return await POST(BASE_URL + "/ltbi/createUpdateHistory", body);
};

export const getDiagnosis = async (body) => {
  return await GET(BASE_URL + "/ltbi/getDiagnosis/" + body.id, { body });
};

export const createDiagnosis = async (body) => {
  return await POST(BASE_URL + "/ltbi/createUpdateDiagnosis", body);
};
export const getDrug = async (body) => {
  return await GET(BASE_URL + "/ltbi/getDrugs/" + body.id, { body });
};

export const createDrug = async (body) => {
  return await POST(BASE_URL + "/ltbi/createDrugs", body);
};
export const updateDrug = async (body) => {
  return await POST(BASE_URL + "/ltbi/updateDrugs", body);
};

export const deleteDrugs = async (body) => {
  return await POST(BASE_URL + "/ltbi/deleteDrugs", body);
};
export const getAdverseEffect = async (body) => {
  return await GET(BASE_URL + "/ltbi/getEffects/" + body.id, { body });
};

export const createAdverseEffect = async (body) => {
  return await POST(BASE_URL + "/ltbi/createEffects", body);
};

export const updateAdverseEffects = async (body) => {
  return await POST(BASE_URL + "/ltbi/updateEffects", body);
};

export const deleteAdverseEffects = async (body) => {
  return await POST(BASE_URL + "/ltbi/deleteEffects", body);
};

export const getSymptoms = async (body) => {
  return await GET(BASE_URL + "/ltbi/getSymptoms/" + body.id, { body });
};

export const createSymptoms = async (body) => {
  return await POST(BASE_URL + "/ltbi/createSymptom", body);
};
export const updateSymptoms = async (body) => {
  return await POST(BASE_URL + "/ltbi/updateSymptom", body);
};
export const deleteSymptoms = async (body) => {
  return await POST(BASE_URL + "/ltbi/deleteSymptom", body);
};

export const getPatientInvestigations = async (body) => {
  return await GET(BASE_URL + "/ltbi/getInvestigations/" + body.id, { body });
};

export const createPatientInvestigation = async (body) => {
  return await POST(BASE_URL + "/ltbi/createInvestigation", body);
};

export const updatePatientInvestigation = async (body) => {
  return await POST(BASE_URL + "/ltbi/updateInvestigation", body);
};

export const createLevel = async (body) => {
  return await POST(BASE_URL + "/users/createUserLevel", body);
};

export const getUsers = async (body) => {
  return await GET(BASE_URL + "/users/", body);
};

export const getAllLevels = async (body) => {
  return await GET(BASE_URL + "/users/getUserLevels", body);
};

export const getDashboard = async (body) => {
  return await GET(BASE_URL + "/dashboard/", body);
};

export const getPermissions = async (body) => {
  return await GET(BASE_URL + "/users/permissions", body);
};

export const signUp = async (body) => {
  return await POST(BASE_URL + "/users/signup", body);
};

export const getHistorySummary = (ltbi_no) =>
  GET(BASE_URL + `/ltbi/getHistory/${ltbi_no}`, {});

export const getSymptomsSummary = (ltbi_no) =>
  GET(BASE_URL + `/ltbi/getSymptoms/${ltbi_no}`, {});

export const getDiagnosisSummary = (ltbi_no) =>
  GET(BASE_URL + `/ltbi/getDiagnosis/${ltbi_no}`, {});

export const getInvestigationsSummary = (ltbi_no) =>
  GET(BASE_URL + `/ltbi/getInvestigations/${ltbi_no}`, {});

export const getDrugs = (ltbi_no) =>
  GET(BASE_URL + `/ltbi/getDrugs/${ltbi_no}`, {});

export const getEffects = (ltbi_no) =>
  GET(BASE_URL + `/ltbi/getEffects/${ltbi_no}`, {});

export const addPatientOutcome  = async (body) => {
  return await POST(BASE_URL + "/ltbi/addOutcome", body);
};

export const getPatientOutcome = async (body) => {
  return await GET(BASE_URL + "/ltbi/getOutcome/" + body.id, { body });
};
