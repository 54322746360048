import { Table, Button, Card, Form, message } from "antd";
import FormInput from "Components/form/FormInput";
import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import Joi from 'joi';
import { canEditPatient } from "Api/utils";
import {
  createAdverseEffect,
  getAdverseEffect,
  updateAdverseEffects,
  deleteAdverseEffects,
} from "Api";

const AdverseEffect = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [effect, setEffect] = useState([]);
  const [context, setContext] = useState(1);

  const schema = Joi.object({
    effect: Joi.string().required().label('Effect'),
    drug: Joi.string().required().label('Drug'),
    action: Joi.string().required().label('Action'),
  }).unknown(true);

  // load adverse effects and refresh the table
  const loadAdverseEffects = () => {
    if (selected) {
      getAdverseEffect({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data;
        if (data) {
          setEffect(data);
        }
      });
    }
  };

  const onFinish = (values) => {
    const { error } = schema.validate(values, { abortEarly: false });
    if (error) {
      error.details.forEach(detail => {
        message.error(detail.message);
      });
      return;
    }

    const body = {
      ltbi_no: selected.ltbi_no,
      effect: values.effect,
      drug: values.drug,
      action: values.action,
    };

    if (context === 1) {
      createAdverseEffect(body)
        .then(() => {
          form.resetFields();
          message.success("Adverse Effect saved successfully!");
          loadAdverseEffects();
        })
        .catch(() => {
          message.error("Failed to save Adverse Effect.");
        });
    } else if (context === 2) {
      body.id = form.getFieldValue("id");
      updateAdverseEffects(body)
        .then(() => {
          setContext(1);
          form.resetFields();
          loadAdverseEffects();
          message.success("Adverse Effect updated successfully!");
        })
        .catch(() => {
          message.error("Failed to update Adverse Effect.");
        });
    }
  };

  // Load the adverse effects and check if outcome is saved when the selected patient changes
  useEffect(() => {
    if (selected) {
      loadAdverseEffects();
      const checkEditPatient = async () => {
        const canEdit = await canEditPatient(selected.ltbi_no);
        console.log(canEdit);
      };
      checkEditPatient();
    }
  }, [selected]);

  const handleDelete = (record) => {
    deleteAdverseEffects({ id: record.id }).then(() => {
      setEffect((prevEffects) =>
        prevEffects.filter((item) => item.id !== record.id)
      );
    });
  };

  const columns = [
    {
      title: "Effect",
      width: 100,
      dataIndex: "effect",
      key: "Effect",
    },
    {
      title: "Drug",
      width: 100,
      dataIndex: "drug",
      key: "Drug",
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "action",
      key: "Action",
    },
    {
      title: "Followup date",
      width: 100,
      dataIndex: "created",
      key: "created_at",
      render: (created) => {
        return new Date(created).toLocaleDateString();
      },
    },
    {
      title: "Action",
      key: "operation",
      width: 100,
      render: (_, record) => (
        !selected?.finished && (
          <div className="row">
            <div className="col-md-4">
              <Button
                style={{ color: "green" }}
                onClick={() => {
                  setContext(2); // Set to update mode
                  form.setFieldsValue({
                    id: record.id,
                    effect: record.effect,
                    drug: record.drug,
                    action: record.action,
                  }); // Populate form with selected record
                }}
              >
                Update
              </Button>
            </div>
            <div className="col-md-4">
              <Button
                style={{ color: "red", marginLeft: "10px" }}
                onClick={() => handleDelete(record)} 
              >
                Delete
              </Button>
            </div>
          </div>
        )
      ),
    },
  ];

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-4">
            <FormInput name="id" type="hidden" />
            
            <Form.Item
              label={<span>Effect <span style={{ color: 'red' }}>*</span></span>}
              name="effect"
            >
              <Input disabled={selected?.finished} />
            </Form.Item>

            <Form.Item
              label={<span>Drug <span style={{ color: 'red' }}>*</span></span>}
              name="drug"
            >
              <Input disabled={selected?.finished} />
            </Form.Item>

            <Form.Item
              label={<span>Action <span style={{ color: 'red' }}>*</span></span>}
              name="action"
            >
              <Input disabled={selected?.finished} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" disabled={selected?.finished}>
                {context === 1 ? "Add" : "Update"}
              </Button>
            </Form.Item>

            {!!selected?.finished && (
              <p style={{ color: "red", fontWeight: "bold", textAlign: "left" }}>
                Treatment already finished
              </p>
            )}
          </div>
          <div className="col-md-8">
            <Table
              columns={columns}
              dataSource={effect}
              rowKey="id" //unique key
              scroll={{ x: 400 }}
              sticky
              className="mt-3"
            />
          </div>
        </div>
      </Card>
    </Form>
  );
};

export default AdverseEffect;
