import { Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { items } from "../../Constants";

const SideBar = () => {
    const collapsed = useSelector((state) => state.layout.collapsed);
    const navigate = useNavigate();

    return (
        <Sider trigger={null} collapsible collapsed={collapsed}>
            <div className='fos-logo text-center mt-3'>
                {collapsed ? <span>LTBI</span> : <span>LTBI</span>}
            </div>
            <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
                {items.map(item => {
                    if (item && item.nav) {
                        if (item.children) {
                            return (
                                <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
                                    {item.children.map(child => (
                                        <Menu.Item
                                            key={child.key}
                                            icon={child.icon}
                                            onClick={() => { if (child.url) navigate(child.url); }}
                                        >
                                            {child.label}
                                        </Menu.Item>
                                    ))}
                                </Menu.SubMenu>
                            );
                        } else {
                            return (
                                <Menu.Item
                                    key={item.key}
                                    icon={item.icon}
                                    onClick={() => { if (item.url) navigate(item.url); }}
                                >
                                    {item.label}
                                </Menu.Item>
                            );
                        }
                    } else {
                        return null;
                    }
                })}
            </Menu>
        </Sider>
    );
}

export default SideBar;
