import { Button, Card, Form, message } from "antd";
import FormInput from "Components/form/FormInput";
import { DISTRICTS } from "Constants";
import FormSelect from "Components/form/FormSelect";
import FormRadioGroup from "Components/form/FormRadioGroup";
import { GENDER } from "Constants";
import FormMulti from "Components/form/FormMulti";
import { CATEGORIES } from "Constants";
import { SYMPTOMS } from "Constants";
import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { CLOSE_CATEGORY, CLINICAL_RISK, VULNERABLE } from "Constants";
import { updatePatient,getPatient } from "Api";
import { setAlert } from "Reducers/Layout";
import { setSelected } from 'Reducers/Patients';

const General = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected);
  const [presumptive, setPresumptive] = useState(0);
  const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    if (selected?.ltbi_no) {
      const payload = {
        ...values,
        dob: values.dob ? dayjs(values.dob).format("YYYY-MM-DD") : null,
        ltbi_no: selected?.ltbi_no,
        hiv_positive: selected?.hiv_positive,
      };
      setLoading(true);
      updatePatient(payload)
        .then(() => {
          dispatch(
            setAlert({
              type: "success",
              message: "Patient updated successfully",
            })
          );
          getPatient({ id: selected?.ltbi_no }).then((res) => {
            const data = res?.data?.at(0);
    
            if (data) {
              console.log( {...selected, ...data} );

              dispatch(setSelected({...selected, ...data}))
            }
            setLoading(false);

          }).catch((e) => {
            console.log(e);
            dispatch(
              setAlert({
                type: "error",
                message: "Error fetching  updated patient recode",
              })
            );
            setLoading(false);
          });
        })
        .catch(() => {
          setLoading(false);
          dispatch(
            setAlert({
              type: "error",
              message: "Failed to update patient.",
            })
          );
        });
    } else {
      dispatch(
        setAlert({
          type: "error",
          message: "LTBI number is missing, cannot update patient.",
        })
      );
    }
  };

  useEffect(() => {
    if (selected) {
      console.log("SELECTED", selected);
      Object.entries(selected).forEach(([key, value]) => {
        form.setFieldValue(key, key === "dob" ? dayjs(value) : value);
      });
      setPresumptive(selected["presumptive_category"]);

      const symptoms = selected.symptoms;
      symptoms?.forEach((s) => form.setFieldValue("symptoms", s.symptom));
    } else {
      form.resetFields();
    }
  }, [form, selected]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <Card className="p-2">
        <div className="row">
          <div className="col-md-3">
            <FormInput
              label="District LTBI Number"
              name="ltbi_patient_no"
              value={selected?.ltbi_no}
              disabled
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="District LTBI Screening Number"
              name="screening_no"
              disabled
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Standard Card Number"
              name="standard_card_no"
              disabled
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Patient identification number"
              name="pin"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormSelect
              label="District"
              name="district"
              options={DISTRICTS}
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Name"
              name="name"
              required
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormRadioGroup
              label="Sex"
              name="sex"
              options={GENDER}
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Date of birth"
              name="dob"
              type="date"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Past TB History"
              name="past_tb"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Mobile"
              name="mobile_phone"
              type="number"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Home phone number"
              name="home_phone"
              type="number"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormInput
              label="Guardian phone number"
              name="guardian_phone"
              type="number"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              label="Permanent address"
              name="permanent_address"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-6">
            <FormInput
              label="Residential address"
              name="residential_address"
              disabled={selected?.finished}
            />
          </div>
          <div className="col-md-3">
            <FormSelect
              label="Presumptive LTBI Category"
              name="presumptive_category"
              setVal={(val) => {
                setPresumptive(val);
              }}
              options={CATEGORIES}
              disabled={selected?.finished}
            />
          </div>
          {presumptive !== 1 && presumptive !== 5 ? (
            <div className="col-md-3">
              <FormSelect
                label="Presumptive LTBI Category"
                name="presumptive_category_sub"
                options={
                  presumptive === 2
                    ? CLOSE_CATEGORY
                    : presumptive === 3
                    ? CLINICAL_RISK
                    : VULNERABLE
                }
              />
            </div>
          ) : null}
          {presumptive === 2 ? (
            <div className="col-md-3">
              <FormInput
                label="DTB No. of TB patient"
                name="index_patient"
                required
                disabled={selected?.finished}
              />
            </div>
          ) : null}
          {presumptive === 5 ? (
            <div className="col-md-3">
              <FormInput
                label="Other reason"
                name="other_reason"
                required
                disabled={selected?.finished}
              />
            </div>
          ) : null}
          <div className="col-md-12">
            <FormMulti
              label="Symptoms"
              name="symptoms"
              options={SYMPTOMS}
              setVal={(key, val) => {
                form.setFieldValue(key, val);
              }}
              defaults={
                selected ? selected?.symptoms?.map((obj) => obj.symptom) : []
              }
            />
          </div>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              disabled={selected?.finished}
            >
              Submit
            </Button>
          </Form.Item>
          {!!selected?.finished && (
            <p style={{ color: "red", fontWeight: "bold", textAlign: "left" }}>
              Treatment already finished
            </p>
          )}
        </div>
      </Card>
    </Form>
  );
};

export default General;
