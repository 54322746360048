import { Button, Modal, Card, Form, message, DatePicker } from "antd";
import FormInput from "Components/form/FormInput";
import FormSelect from "Components/form/FormSelect";
import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { OUTCOME } from "Constants";
import { addPatientOutcome, getPatientOutcome } from "Api";
import { setSelectedFinished } from 'Reducers/Patients';

const Outcome = () => {
  const [form] = useForm();
  const selected = useSelector((state) => state.patients.selected); // Selected patient
  const [presumptive] = useState(0);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();

  const showConfirmationModal = () => {
    setConfirmationVisible(true);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      form.submit();
    }
    setConfirmationVisible(false);
  };

  const onFinish = (values) => {
    if (selected && values) {
      const body = {
        ltbi_no: selected.ltbi_no,
        outcome: values?.outcome || 0,
        date_out: dayjs(values?.date_out).format("YYYY-MM-DD"),
        comments: values.comments || null,
      };

      addPatientOutcome(body)
        .then((response) => {
          message.success("Outcome saved successfully!");
          dispatch(setSelectedFinished(1))
          setIsEditable(false); 
        })
        .catch((error) => {
          message.error("Failed to save outcome.");
        });
    } else {
      message.warning("No patient selected or invalid form data.");
    }
  };

  useEffect(() => {
    if (selected) {
      getPatientOutcome({ id: selected?.ltbi_no }).then((res) => {
        const data = res?.data?.at(0);

        if (!data) {
          setIsEditable(true);
          return;
        }
        setIsEditable(false);
        Object.entries(data).forEach(([key, value]) => {
          form.setFieldValue(key, key === "date_out" ? dayjs(value) : value);
        });
      });
    } else {
      form.resetFields();
    }
  }, [form, selected, dispatch]);

  useEffect(() => {
    form.setFieldValue("presumptive_category_sub", null);
  }, [form, presumptive]);

  return (
    <Form onFinish={onFinish} form={form} layout="vertical">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <Card className="p-2">
            <div className="row">
              <div className="col-md-6">
                <FormSelect label="Outcome" name="outcome" options={OUTCOME} disabled={!isEditable}/>
              </div>
              <div className="col-md-6">
                <Form.Item label="Past TB history" name="date_out" >
                  <DatePicker style={{ width: "100%" }} disabled={!isEditable}/>
                </Form.Item>
              </div>
              <div className="col-md-6">
                <FormInput label="Comments" name="comments" type="textarea" disabled={!isEditable}/>
              </div>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                {isEditable ? (
                  <Button type="primary" onClick={showConfirmationModal} disabled={!isEditable}>
                    Finish Treatment
                  </Button>
                ) : (
                  <p style={{ color: "red", fontWeight: "bold", textAlign: "left" }}>
                  Treatment already finished
                  </p>                )}
              </Form.Item>
            </div>
          </Card>
        </div>
        <div className="col-md-2"></div>
      </div>

      <Modal
        title="Confirmation"
        visible={confirmationVisible}
        onOk={() => handleConfirmation(true)}
        onCancel={() => handleConfirmation(false)}
        okText="Yes"
        cancelText="No"
      >
        Are you sure you want to finish the treatment?
      </Modal>
    </Form>
  );
};

export default Outcome;
