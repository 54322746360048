import { Form, Radio } from "antd";

const FormRadioGroup = ({ label = "", name = "", options = [], setVal = () => { } }) => {
    return <Form.Item label={label} name={name}>
        <Radio.Group onChange={(e)=>setVal(e.target.value)}>
            {options.map((opt) => <Radio.Button key={opt.value} value={opt.value}>{opt.text}</Radio.Button>)}
        </Radio.Group>
    </Form.Item>
}

export default FormRadioGroup;