import { getPatientOutcome } from "Api";

export const canEditPatient = async (ltbi_no) => {
    try {
        const res = await getPatientOutcome({ ltbi_no });

        if (res?.data?.length > 0) {
            return false;
        }

        return true;
    } catch (error) {
        console.error("Failed to fetch data", error);
        return false;
    }
};