import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Card, Input, Progress } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setRecords, setSelected } from 'Reducers/Patients';
import { Link } from 'react-router-dom';
import { getPatients } from 'Api';


function PatientRecords() {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowsAll, setRowsAll] = useState([]);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getPatients().then((out) => {
      console.log(out.data);
      setRows(out.data);
      setRowsAll(out.data);
      dispatch(setRecords(out.data));
    })
  }, []);

  const handleModalClose = () => {
    setSelectedRow(null);
    setModalVisible(false);
  };
  const columns = [
    {
      title: 'LTBI No',
      width: 100,
      dataIndex: 'ltbi_patient_no',
      key: 'ltbi_patient_no',
    },
    {
      title: 'Screening No',
      width: 100,
      dataIndex: 'screening_no',
      key: 'screening_no',
    },
    {
      title: 'Name',
      width: 150,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Address',
      dataIndex: 'permanent_address',
      key: '1',
      width: 150,
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile_phone',
      key: '2',
      width: 100,
    },
    {
      title: 'Stage',
      key: 'stage',
      width: 35,
      render: (_, record) => (
        <Progress size={30} type='circle' percent={record.stage * (100 / 3)} showInfo={false} />
      ),
    },
    {
      title: 'Action',
      key: 'operation',
      width: 100,
      render: (_, record) => (
        <div className='row'>
          <div className='col-md-4'>
            <Button onClick={() => { dispatch(setSelected(record)); console.log("REC", record); }}>
              Select
            </Button>
          </div>
          <div className='col-md-4'>
            {record.stage == 3 ? <Button color='secondary'>
              Enroll
            </Button> : null}
          </div>
        </div>
      ),
    },
  ];


  return (
    <div>
      <Card className="p-2">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-4">
                <div>
                  <div className="fos-label mt-2">Name</div>
                  <div className="mt-1">
                    <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div>
                  <div className="fos-label mt-2">Mobile</div>
                  <div className="mt-1">
                    <Input placeholder="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-4">
              <Button className="fos-btn-border" onClick={() => {
                setRows(rowsAll);
                setMobile("");
                setName("");
              }}>Clear</Button>
              <Button className="fos-btn-primary ml-4" onClick={() => {
                const recs = rowsAll.filter((rec) =>
                  rec.name.toString().toLowerCase().includes(name.toString().toLowerCase()) &&
                  rec.mobile_phone.toString().toLowerCase().includes(mobile.toString().toLowerCase()))
                setRows(recs);
              }}>Search</Button>
            </div>
          </div>
        </div>
      </Card>
      <Table
        columns={columns}
        dataSource={rows}
        scroll={{ x: 1400, }} sticky
        className='mt-3'
      />
      {selectedRow && (
        <Modal
          title={<div className='fos-heading text-center'>Action</div>}
          visible={modalVisible}
          onCancel={handleModalClose}
          footer={[
            // <Button key="close" onClick={handleModalClose}>
            //   Close
            // </Button>,
          ]}
        >
          <div className='d-flex justify-content-between mt-4'>
            <Button className='fos-btn-primary'> Push to Funding List </Button>
            <Button className='fos-btn-primary'> Deactivate </Button>
            <Button className='fos-btn-primary'> Re Enroll </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default PatientRecords;
